<template>
    <CategoryTable ref="catTable" :items="items" :fetchItems="fetchItems" :itemsAtEnd="itemsAtEnd" :path="path"
        :tableColumns="tableColumns" :filters="filters" :sorting="sorting" @filterUpdate="filterUpdate"
        @searchUpdate="searchUpdate" @sortUpdate="sortUpdate" />
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import CategoryTable from '@/components/widgets/categoryTable/CategoryTable.vue';
// import IdEncoder from '@/utils/IdEncoder';

const store = useStore()
store.dispatch("fetchProfileFilters")
const router = useRouter()

const queryParameters = ref({ before: "", after: "", searchFilter: "" })
const items = ref([])
const itemsAtEnd = ref(false)
const catTable = ref(null)

const getPrimary = (items, field) => {
    let val = ''
    items?.forEach((item) => {
        if (item.isPrimary) {
            val = item[field]
            return val
        }
    })
    return val
}

const fetchItems = async (numRequested, reset) => {
    reset = reset ?? false

    // Set the query parameters that need to be customized for the next query
    if (reset) queryParameters.value = { ...queryParameters.value, first: numRequested, after: "" }
    else queryParameters.value = { ...queryParameters.value, first: numRequested }

    // Make the query to the server
    const { people, pageInfo } = await store.dispatch("getPeople", queryParameters.value)

    // TODO:    The 'subtitle' currently uses the first job listed in secondaryJobs.
    //          There should be some consensus about which job should be shown, since
    //          users can have multiple jobs. Users with multiple jobs can currently
    //          choose which of their jobs should be shown in their ProfileHeader.
    //
    //          I am not sure if this approach should be taken here, since this is
    //          the data that goes into the CategoryTables. Having one person with
    //          a very long list of jobs could result in an inconsistent height of
    //          the items displayed in the CategoryTable in Grid View.
    //          When CategoryTable is in Table View the column containing jobs
    //          would need to be much wider to accommodate a user with a long
    //          list of jobs.

    const newItems = people.map(person => ({
        id: person.id,
        title: person.fullName,
        subtitle: person.secondaryJobs?.[0]?.title,
        image: (person.profilePhoto)?person.profilePhoto:'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/profile_placeholder.png',
        location: person.location,
        to: { name: 'Profile', params: { id: person.id } },
        country: getPrimary(person.contactinfo?.addresses, "country"),
        city: getPrimary(person.contactinfo?.addresses, "city"),
        email: getPrimary(person.contactinfo?.emails, "email"),
        phone: getPrimary(person.contactinfo?.phoneNumbers, "phoneNumber")
    }))

    
    console.log('newItems', newItems)
    // Set the after value for next query
    queryParameters.value = { ...queryParameters.value, after: pageInfo.endCursor }
    itemsAtEnd.value = !pageInfo.hasNextPage

    // Insert the new items
    if (reset) items.value = [...newItems]
    else items.value = [...items.value, ...newItems]
}

const sortUpdate = (sortBy) => {
    queryParameters.value = { ...queryParameters.value, sortBy }
    catTable.value.runFetchItems(24, true)
}

const filterUpdate = (selectedFilters, filtersOnInclude) => {
    const entries = Object.entries(selectedFilters)   // Get object as entries
        .map(([key, val]) => [key, val.join(",")])    // Combine multiple values with commas
    queryParameters.value = { ...queryParameters.value, ...Object.fromEntries(entries) }
    // TODO: Add include/exclude functionality when it is available in the server
    filtersOnInclude;

    catTable.value.runFetchItems(24, true)
}

const searchUpdate = newSearchString => {
    queryParameters.value.searchFilter = newSearchString
    catTable.value.runFetchItems(24, true)
}

const path = [
    { label: "People", goto: () => router.push({ name: "People" }) },
]

const tableColumns = [
    { label: "Person", key: "title", subkey: "subtitle", size: 4 },
    { label: "Location", key: "city", subkey: "country", size: 4 },
    { label: "Contact", key: "email", keyType: "email", subkey: "phone", subkeyType: "phone", size: 4 },
]

const sorting = [
    // {
    //     label: "Name",
    //     key: "title",
    //     outKey: "first_name",
    //     ordering: "Alphabetical"
    // },
    {
        label: "Job",
        key: "job",
        outKey: "primary_job",
        ordering: "Alphabetical"
    },
    // {
    //     label: "Location",
    //     key: "countries",
    //     outKey: "contactinfo",
    //     ordering: "Alphabetical"
    // },
]

const filters = computed(() => [
    // {
    //     label: "Tag",
    //     key: "tag",
    //     includeExclude: false,
    //     category: "Temp Test",
    //     type: "Textbox",
    //     options: []
    // },
    {
        label: "Job",
        key: "secondaryJobs",
        includeExclude: false,
        category: "General",
        type: "Dropdown",
        options: store.getters.getFilterOptions("profileJobs")
    },
    {
        label: "Gender",
        key: "genders",
        includeExclude: false,
        category: "General",
        type: "Dropdown",
        options: store.getters.getFilterOptions("genders")
    },
    // {
    //     label: "Age",
    //     key: "age",
    //     includeExclude: false,
    //     category: "General",
    //     type: "NumRange",
    //     options: [0, 100]
    // },
    // {
    //     label: "Country",
    //     key: "countries",
    //     includeExclude: true,
    //     category: "Location",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("countries")
    // },
    // {
    //     label: "City",
    //     key: "cities",
    //     includeExclude: true,
    //     category: "Location",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("cities")
    // },
    // {
    //     label: "Body Type",
    //     key: "bodyTypes",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("bodyTypes")
    // },
    // {
    //     label: "Skin Colour",
    //     key: "skinColours",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("skinColour")
    // },
    // {
    //     label: "Height",
    //     key: "height",
    //     includeExclude: false,
    //     category: "Physical",
    //     type: "NumRange",
    //     options: [0, 220]
    // },
    // {
    //     label: "Eye Colour",
    //     key: "eyeColours",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("eyeColour")
    // },
    // {
    //     label: "Hair Colour",
    //     key: "hairColours",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("hairColour")
    // },
    // {
    //     label: "Hair Length",
    //     key: "hairLengths",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("hairLength")
    // },
    // {
    //     label: "Hair Type",
    //     key: "hairTypes",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("hairType")
    // },
    // {
    //     label: "Beard Type",
    //     key: "beardTypes",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("beardType")
    // },
    // {
    //     label: "Beard Color",
    //     key: "beardColours",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("beardColour")
    // },
    // {
    //     label: "Piercings",
    //     key: "piercings",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("piercings")
    // },
    // {
    //     label: "Tattoos",
    //     key: "tattoos",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("tattoos")
    // },
    // {
    //     label: "Voice Type",
    //     key: "voiceTypes",
    //     includeExclude: true,
    //     category: "Physical",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("voiceType")
    // },
    // {
    //     label: "Disabilities",
    //     key: "disabilities",
    //     includeExclude: true,
    //     category: "Diversity",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("disabilities")
    // },
    // {
    //     label: "Other skills",
    //     key: "additionalSkills",
    //     includeExclude: true,
    //     category: "Skills",
    //     type: "Dropdown",
    //     options: store.getters.getFilterOptions("profileSkills")
    // },
])
</script>
